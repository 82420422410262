

enum Actions {
    // action types
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",
    ADD_BODY_ATTRIBUTE = "addBodyAttribute",
    REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
    ADD_CLASSNAME = "addClassName",
    VERIFY_AUTH = "verifyAuth",
    LOGIN = "login",
    LOGOUT = "logout",
    REGISTER = "register",
    UPDATE_USER = "updateUser",
    FORGOT_PASSWORD = "forgotPassword",
    SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
    LOGIN_SSO = "loginSSO",
    GET_MACHINES = "getMachines",
    GET_MACHINE = "getMachine",
    GET_USER_INSTACE = "getUserInstance",
    GET_MACHINE_ACTIVITY = "getMachineActivity",
    GET_CHECKOUT_ITEMS = "getCheckoutItems",
    GET_CHECKOUT_PLANS = "getCheckoutPlans",
    GET_CARD_TOKEN = "getCardToken",
    GET_ALL_COURSES = "getAllCourses",
    GET_COURSE = "getCourse",
    GET_VPN_CONFIG = "getVPNConfig",
    GET_MACHINES_FILTERED = "getMachinesFiltered",
    UPDATE_CURRENT_ACTIVE_CHECKOUT = "updateCurrentActiveCheckout",
    GET_LATEST_ORDER_STATUS = "getLatestOrderStatus",
  }
  
  enum Mutations {
    // mutation types
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
    PURGE_AUTH = "logOut",
    SET_AUTH = "setAuth",
    SET_USER = "setUser",
    SET_PASSWORD = "setPassword",
    SET_ERROR = "setError",
    SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
    SET_LAYOUT_CONFIG = "setLayoutConfig",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
    OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
    SET_MACHINES = "setMachinesonStore",
    SET_CURRENT_MACHINE = "setCurrentMachine",
    START_INSTANCE = "startInstance",
    STOP_INSTANCE = "stopInstance",
    SET_CURRENT_MACHINE_ACTIVITY = "setCurrentMachineActivity",
    UPDATE_USER = "updateUser",
    SET_CHECKOUT_ITEMS = "setCheckoutItems",
    SET_CHECKOUT_PLANS = "setCheckoutPlans",
    ADD_ITEM_TO_CART = "addItemToCart",
    CLEAR_CHECKOUT = "clearCheckout",
    SET_CHECKOUT = "setCheckout",
    SET_CARD_DATA = "setCardData",
    SET_CARD_TOKEN = "setCardToken",
    SET_PAYMENT_METHOD = "setPaymentMethod",
    SET_COURSE_DATA = "setCourseData",
    SET_CURRENT_COURSE_DATA = "setCurrentCourseData",
    SET_INSTALLMENTS = "setInstallments",
    SET_ADDRESS_DATA = "setAddressData",
    SET_VPN_DATA  = "setVpnData",
    SET_MACHINES_METADATA = "setMachinesMetadata",
    SET_CURRENT_MACHINES_LIST = "setCurrentMachinesList",
    SET_CURRENT_COUPON = "setCurrentCoupon",
    SET_ACTIVE_CHECKOUT = "setActiveCheckout",
  }
  
  export { Actions, Mutations };
  
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import router from "@/router";

export interface User {
  name: string;
  email: string;
  avatar: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = false;
  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

 
  /**
   * Get current user name
   */
  get getUserName() {
    return this.user.name;
  }
  

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error: any) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user : any ) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
  }

  @Mutation
  [Mutations.SET_USER](user : any) {
    this.user = user;
  }

 
  @Mutation
  async [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    console.log(router.currentRoute.value.name)
    router.push("/sign-in");
  }

  @Mutation
  [Mutations.UPDATE_USER](user : any)
  {
    this.user = user;
    ApiService.put("account/profile", user)
    .then(({ data }) => {
    this.context.commit(Mutations.SET_AUTH, data);
    })
    .catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR, response.data.errors);
    });
  }
  
  @Action
  async [Actions.LOGIN](credentials: any) {
    return;

  }

  @Action
  async [Actions.LOGOUT]() {
    await ApiService.get("auth/logout");
    await this.context.commit(Mutations.PURGE_AUTH);
  
  }
 
  
  @Action
  [Actions.FORGOT_PASSWORD](payload : any) {
    const params = {
        ...payload,
    };
    return ApiService.post("password/forgot", params)
      .then(({ data }) => {
        if(data.success) {
          return true;
        }else{
           throw "error";
        }
      })
      .catch(({ response }) => {
        throw "error";
      });
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
   
      const params = {};
      await ApiService.query("/user", params as AxiosRequestConfig)
        .then(({ data } : any) => {
        
          
          this.context.commit(Mutations.SET_AUTH, data.data);
        })
        .catch(() => {
      
          this.context.commit(Mutations.PURGE_AUTH);
        });

    
   
  }
}

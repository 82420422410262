
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    return {
      store
    }
  },
});

import { createRouter, RouteRecordRaw, createWebHistory } from "vue-router";
import store from "@/store";
import ApiService from "@/core/services/ApiService";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/views/base/BaseAuthComponent.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/auth/SignIn.vue"),
      },
      {
        path: "/oauth/:provider",
        name: "oauth-provider",
        component: () =>
          import("@/views/auth/Oauth.vue"),
        props: (route) => ({ provider: route.params.provider }),
      },
      {
        path: "/auth/:provider/callback",
        name: "oauth-provider-callback",
        component: () =>
          import("@/views/auth/Oauth-Callback.vue"),
        props: (route) => ({ provider: route.params.provider }),
      }
    ],
  },
  {
    path: "/",
    redirect: "/instance",
    component: () => import("@/views/base/BaseComponent.vue"),
    children: [
      {
        path: "/instance",
        name: "instance",
        component: () => import("@/views/instance/Index.vue"),
      },
      {
        path: "/resource",
        name: "resource",
        component: () => import("@/views/resource/Index.vue"),
      },
      {
        path: "/capacity",
        name: "capacity",
        component: () => import("@/views/capacity/Index.vue"),
      },
      {
        path: "/organization",
        name: "organization",
        component: () => import("@/views/organization/Index.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/errors/404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((ctx) => {
  

  const whiteListRoutes = [
    "sign-in",
    "sign-up",
    "oauth-provider",
    "oauth-provider-callback",
    "privacy-and-terms",
    "verify-email",
    "password-reset-validation"
  ];
 
  const currentRoutename = <string>ctx.name;
  
  if (!whiteListRoutes.includes(currentRoutename)) {
    ApiService.get("/user").then((data) => {
      console.log(data);
      // dispatch set auth action
      store.commit('setAuth', data.data);
    
    }).catch((error) => {
      if(error.response.status == 401){
        window.location.href = "/sign-in"
      }
    });
  }
  
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
